import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';


import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../components/contact';

import Styles from './index.module.css';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['gatsby', 'application', 'react']} />

    <section className={`${Styles.section} ${Styles.about}`}>
      <div className="container">
        <p className="lead">Sourcecraft はフリーランスのシステム開発事務所です。</p>
        <p>IT に関わるサービスの制作を行うかたわら、フリーランスとしてシステム開発、運用のプロジェクトに参加しています。</p>

        <a className="btn btn-outline-light" role="button" href="/about">
          <i className="material-icons">more</i>
          もっと知る
        </a>
      </div>
    </section>

    <section className={Styles.section}>
      <div className="container">
        <h2>Service</h2>
        <p>提供しているサービス、ソフトウェアです。</p>

        <div className="row">
          <div className="col-sm">
            <a href="https://image-arts.app.sourcecraft.jp/"><ImageService1 /></a>
          </div>
          <div className="col-sm">
            <h3><a href="https://image-arts.app.sourcecraft.jp/">Imarge Arts</a></h3>
            <p>Image Arts はシンプルな画像編集ツールです。</p>
            <ul>
              <li>画像のサイズ変更</li>
              <li>画像の切り取り</li>
              <li>ファイルタイプの変換</li>
              <li>Undo & Redo</li>
            </ul>
            <p>
              Pure Javascript 製で、すべての処理をクライアント側のブラウザが行うため、サーバーとの通信がありません。
              画像ファイルの通信を行わないため、セキュアで高速です。
            </p>
          </div>
        </div>

      </div>
    </section>

    <section className={Styles.section}>
      <div className="container">
        <h2>Contact</h2>
        <p>何かありましたら、以下のフォームからメッセージをお送りください。</p>
        <Contact />
      </div>
    </section>
  </Layout>
);

const ImageService1 = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "screenshot_imagearts.png" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <GatsbyImage
        fluid={data.placeholderImage.childImageSharp.fluid}
        className={Styles.thumbnail}
      />
    )}
  />
);

export default IndexPage;
