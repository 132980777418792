import React from 'react';

import Styles from './contact.module.css';

/* global document */

class Contact extends React.Component {
  componentDidMount() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  render() {
    return (
      <form
        name="contact"
        action="/contact/thanks/"
        className={`needs-validation ${Styles.form}`}
        noValidate
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />


        <div className="form-group">
          <label htmlFor="inputName">お名前</label>
          <input type="text" className={`form-control ${Styles.inputName}`} id="inputName" name="name" required />
          <div className="valid-feedback">OK</div>
          <div className="invalid-feedback">「お名前」を入力してください</div>
        </div>

        <div className="form-group">
          <label htmlFor="inputEmail">Eメールアドレス</label>
          <input type="email" className={`form-control ${Styles.inputEmail}`} id="inputEmail" name="email" required />
          <div className="valid-feedback">OK</div>
          <div className="invalid-feedback">「Eメールアドレス」を入力してください</div>
        </div>

        <div className="form-group">
          <label htmlFor="inputMessage">メッセージ</label>
          <textarea className="form-control" id="inputMessage" rows="8" name="message" required />
          <div className="valid-feedback">OK</div>
          <div className="invalid-feedback">「メッセージ」を入力してください</div>
        </div>

        <div className="form-group">
          <div data-netlify-recaptcha />
        </div>

        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            <i className="material-icons">email</i>
            メッセージを送る
          </button>
        </div>
      </form>
    );
  }
}

export default Contact;
